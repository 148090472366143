import apiService from '@/common/api.service'

export function getFaqList () {
  return apiService({
    url: '/api/faq/list',
    method: 'get'
  })
}

export function getFaqData (id) {
  return apiService({
    url: '/api/faq',
    method: 'get',
    params: { id }
  })
}

export function createFaq (data) {
  return apiService({
    url: '/api/faq',
    method: 'post',
    data
  })
}
export function deleteFaq (id) {
  return apiService({
    url: '/api/faq',
    method: 'delete',
    data: { id }
  })
}
export function updateFaq (data) {
  return apiService({
    url: '/api/faq',
    method: 'patch',
    data
  })
}

export function uploadFile (file) {
  const data = new FormData()
  data.append('file', file)
  return apiService({
    url: '/api/faq/file',
    method: 'post',
    data
  })
}

export function getFile (url) {
  return apiService({
    url: '/api/faq/file',
    method: 'get',
    responseType: 'blob',
    params: { file_uri: url }
  })
}
